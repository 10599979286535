<template>
  <div>
    <!--begin::Events-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Event Details</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToEventsList()">
                  Back
                </span>
              </button>
              <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
                v-if="user_permissions.includes('chapter_add_event') || is_company_admin == 1"
              >
                <span class="v-btn__content" @click="editEvent()">
                  Edit
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_event_form">
          <div class="example mb-10">
            <v-form ref="form">
              <show-fields v-if="load_component" v-bind:event="event" />              
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::Events-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import { format, parseISO } from "date-fns";
import Swal from "sweetalert2";
import ShowFields from "./ShowFields.vue";

export default {
  name: "EventForm",
  vuetify: new Vuetify(),
  components: {
    ShowFields
  },
  
  data() {
    return {
    user_permissions:[],
      event: {
        name: "",
        date: format(
          parseISO(new Date().toISOString()),
          "yyyy-MM-dd hh:mm"
        ),
        time: "",
        location: "",
        price: "",
        available_for_volunteer: 0,
        available_for_attend: 0,
        no_of_volunteers_needed: "",
        volunteer_roles: ""
      },
      load_component: false,
      is_company_admin : 2,
    };
  },
  computed: {
    ...mapGetters(["getUserPermissions"])
  },
  watch: {
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      axios({
        method: "get",
        url: "events/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.events) {
            context.event = result.data.events;
            context.load_component = true;
          } else {
            // context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "Event load error!", "error");
          }
        },
        function() {
          context.$router.go(-1); //go back to list
          context.disable_btn = false;
          Swal.fire("Error", "Event not found!", "error");
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Event" }]);
    this.$store.dispatch(GET_COMPANY);
    EventBus.$on("GET_COMPANY", function() {});
    EventBus.$on("GET_IS_COMPANY_ADMIN", function(newVal) {
        context.is_company_admin = newVal;
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    redirectToEventsList() {
      let context = this;
      context.$router.go(-1);
    },
    editEvent() {
      this.$router.push({
        name: "events.edit",
        params: { id: this.$route.params.id }
      });
    }
  }
};
</script>
